import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

const ContentPage = ({ data, location, ...props }) => {
    return (
        <SeoPage
            leadImage={data.img0.childImageSharp.fluid.src}
            location={location}
            title="Refus de souffler dans l'alcootest – coupable automatiquement?"
            description="Être derrière le volant d’un véhicule alors que vous êtes en état d’ébriété peut vous mener à de lourdes conséquences. Cela est également vrai si vous refusez de souffler dans l’alcootest."
            fr={undefined}
            lawType="criminal"
            specialtyText="spécialisé en alcool au volant">
            <p>
                <strong>
                    Être intercepté au volant d’un véhicule alors que vous êtes
                    en état d’ébriété vient avec de lourdes conséquences.
                </strong>
            </p>
            <p>
                Vous avez récemment été intercepté par un policier qui avait des
                motifs de croire que vous étiez en état d’ébriété?{' '}
            </p>
            <p>
                <Img
                    fluid={data.img0.childImageSharp.fluid}
                    title="Puis-je refuser de souffler dans l’alcootest?"
                    alt=""
                />
            </p>
            <p>
                Si vous avez bu, il est probable que vous souhaitiez éviter les
                conséquences. Dans ce cas, il est possible que vous pensiez que
                refuser d’obtempérer soit la solution logique à votre problème.
            </p>
            <p>
                <strong>Détrompez-vous</strong>! Afin d'éviter cette situation,
                le législateur a mis en place une infraction permettant de
                couvrir les cas similaires à celui mentionné ci-dessus. De plus,
                dans un tel cas, les sanctions sont plus strictes que celles de
                l’alcool au volant.
            </p>
            <p>
                Toutefois,{' '}
                <strong>
                    est-ce que tous les refus équivalent à une déclaration de
                    culpabilité?{' '}
                </strong>
                Évidemment que non. Bien que rare, il existe quelques moyens de
                défense qui s’appliquent dans une telle situation.
            </p>
            <p>
                <strong>
                    Pour savoir comment vous défendre lors d’une accusation de
                    refus de souffler dans l’alcootest, lisez ce guide complet
                    offert par Avocats Alcool au Volant!
                </strong>
            </p>
            <h2>À quel moment un policier peut-il exiger un alcootest?</h2>
            <p>
                Tout d’abord, avant même de prendre connaissance des
                conséquences associées au refus de souffler dans l’alcootest, il
                est important de savoir <strong>à quel moment</strong> le
                policier a le droit de vous demander de vous soumettre à ce
                test.{' '}
            </p>
            <p>
                Après tout, ce ne sont pas toutes les circonstances qui
                permettent à un agent d’ordonner le prélèvement d’un échantillon
                de votre haleine. En vertu du Code criminel, le policier doit
                avoir <strong>des motifs raisonnables de soupçonner</strong> que
                vous êtes en état d’ébriété.{' '}
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Pas si vite!</strong> Depuis 2018,
                            l’exigence entourant le « motif raisonnable » n’est
                            plus nécessaire et donc, le fardeau reposant sur les
                            épaules du policier est moins élevé qu’il ne l’était
                            auparavant.
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                Désormais, même en l’absence de symptômes d’intoxication, le
                policier pourrait vous soumettre à un alcootest s’il a en sa
                possession l’outil permettant de prélever un échantillon
                d’haleine.{' '}
            </p>
            <p>
                En d’autres termes, dès que vous vous faites intercepter par un
                policier et que vous êtes au volant d’un véhicule, cet agent
                pourrait vous demander immédiatement de souffler dans son
                appareil de détection.{' '}
            </p>
            <h2>
                Puis-je être accusé d’avoir refusé de souffler dans un
                alcootest?
            </h2>
            <p>
                Oui, au Québec, il est possible, d’une certaine manière, d’être
                accusé de refuser de souffler ou d’obtempérer. Ainsi, lorsqu’un
                policier vous intercepte au volant d’un véhicule et qu’il
                soupçonne que vous êtes en état d’ébriété, celui-ci a le droit
                de vous demander de <strong>souffler dans un alcootest </strong>
                et d’obtenir un échantillon de votre haleine.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img1.childImageSharp.fluid}
                    title="Est-ce illégale de ne pas souffler dans l’alcootest"
                    alt=""
                />
            </p>
            <p>
                Si vous vous retrouvez dans une telle situation, la première
                chose à savoir est que cette demande n’est pas simplement une
                suggestion. En effet, il{' '}
                <strong>s’agit bel et bien d’un ordre </strong>que vous vous
                devez de respecter.
            </p>
            <p>
                Par conséquent, si vous prenez la décision de{' '}
                <strong>refuser d’obtempérer</strong> avec le policier et que
                vous ne passez pas l’alcootest, vous aurez automatiquement
                commis une infraction en vertu de l’article 320.15 du Code
                criminel.
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Article 320.15.</strong> Comment une
                            infraction quiconque, sans que l’ordre a été donné,
                            omet ou refuse d’obtempérer à un ordre en vertu des
                            articles 320.27 ou 320.28.
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                Comme c’est le cas lorsque{' '}
                <strong>vous êtes reconnu coupable</strong> de
                <Link to="https://avocatsalcoolauvolant.ca/consequences-alcool-volant/">
                    {' '}
                    l’infraction d’alcool au volant,{' '}
                </Link>
                l’infraction de refuser de passer l’alcootest aura certaines
                conséquences pouvant avoir des effets à long terme:
            </p>
            <ul>
                <li>Une contravention,</li>
                <li>Un casier judiciaire, ou</li>
                <li>Une suspension de permis.</li>
            </ul>
            <p>
                En d’autres termes, le fait de refuser d’obtempérer a des
                conséquences similaires à la reconnaissance de culpabilité pour
                conduite en état d’ébriété. Ainsi, mieux vaut souffler que de
                refuser.{' '}
            </p>
            <h3>
                Quels sont les éléments permettant de démontrer votre
                culpabilité?
            </h3>
            <p>
                Évidemment, pour que vous soyez reconnu coupable d’avoir refusé
                de souffler dans un alcootest, le procureur devra démontrer
                quelques éléments. Tout d’abord, il est nécessaire que vous ayez{' '}
                <strong>reçu l’ordre de souffler</strong> dans l’alcootest de
                manière claire.
            </p>
            <p>
                À chaque fois que le policier vous ordonne d’effectuer ce test,
                il doit démontrer qu’il avait des{' '}
                <strong>
                    motifs raisonnables de soupçonner que vous avez consommé de
                    l’alcool{' '}
                </strong>
                avant de prendre le volant.{' '}
            </p>
            <p>
                Ensuite, le procureur devra prouver que vous avez conduit votre
                automobile dans les <strong>3 heures qui précèdent</strong>{' '}
                votre arrestation. Finalement, il doit prouver que vous avez
                refusé de souffler dans l’alcootest.
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Pas si vite!</strong> Vous pourriez
                            également être reconnu coupable de cette infraction
                            si le procureur prouver que vous aviez l’intention
                            de refuser de souffler ou de ne pas obtempérer avec
                            l’ordre du policier.
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                En ce qui concerne l’<strong>intention de refuser</strong> de
                souffler dans l’alcootest, ce dernier doit nécessairement être
                prouvé étant donné qu’un crime n’est commis qu’en la présence
                d’un esprit coupable de la part de l’accusé. Ainsi, à ce stade,
                un bon avocat pourra présenter un moyen de défense face à une
                telle accusation.
            </p>
            <p>
                <strong>
                    Trouvez l’avocat qu’il vous faut dès aujourd’hui grâce au
                    formulaire en ligne d’Avocats Alcool au Volant!
                </strong>
            </p>
            <h2>
                Quels sont les moyens de défense contre une accusation de refus
                de souffler dans l’alcootest?
            </h2>
            <p>
                Si vous êtes accusé d’avoir refusé d’obtempérer avec l’ordre du
                policier, tout n’est pas perdu pour autant. Même s’il s’agit{' '}
                <strong>d’un geste grave</strong>, il n’en reste pas moins que
                certains moyens de défense existent.{' '}
            </p>
            <h3>La violation de vos droits fondamentaux</h3>
            <p>
                Tout d’abord, lorsque vous faites appel à un avocat, la première
                chose que ce dernier va faire est de vérifier si{' '}
                <strong>le policier a respecté les procédures</strong>. Dans le
                cas inverse, il sera possible de demander l’annulation des
                accusations. Cela sera notamment le cas pour les situations
                suivantes:
            </p>
            <ul>
                <li>Fouilles abusives,</li>
                <li>
                    Violation de vos droits fondamentaux (le droit à un avocat
                    par exemple), et
                </li>
                <li>
                    Le non-respect des procédures (et des délais) judiciaires.{' '}
                </li>
            </ul>
            <p>
                Si l’avocat est en mesure de démontrer une telle situation, il
                est probable que le juge vous <strong>exonère </strong>de
                l’accusation qui est portée contre vous pour le refus
                d’obtempérer. Cependant, il faut s’assurer d’avoir un dossier
                complet et toutes les preuves nécessaires.{' '}
            </p>
            <h3>Votre condition médicale</h3>
            <p>
                Ensuite, selon votre <strong>condition de santé</strong>, il est
                possible que vous présentiez une défense vous permettant de ne
                pas souffler dans l’éthylomètre. Cela sera notamment le cas si
                vous souffrez d’asthme ou d’un autre problème respiratoire.
            </p>
            <p>
                Toutefois, bien que cela justifie le fait que vous n’ayez pas
                soufflé dans l’alcootest, il est tout de même possible que vous
                soyez reconnu coupable d’avoir{' '}
                <strong>conduit avec les facultés affaiblies.</strong>
            </p>
            <h3>L’appareil de détection est non-conforme</h3>
            <p>
                Finalement, il est absolument nécessaire que l’appareil que le
                policier utilisé pour prélever votre haleine soit un{' '}
                <strong>appareil de détection approuvé</strong>. Si ce dernier
                ne respecte pas les exigences imposées par la loi, il est
                probable que la preuve soit automatiquement exclue.
            </p>
            <p>
                <Img
                    fluid={data.img2.childImageSharp.fluid}
                    title="Que faire si je suis accusé d’avoir refusé de souffler dans l’alcootest?"
                    alt=""
                />
            </p>
            <p>
                De plus, le policier qui va prélever votre haleine doit avoir{' '}
                <strong>suivi une formation </strong>et être accrédité afin de
                pouvoir utiliser l’alcootest. Sinon, la procédure sera jugée non
                conforme et vous pourrez être exonéré.{' '}
            </p>
            <h2>
                Refus de passer un alcootest: Quelles sont les conséquences?
            </h2>
            <p>
                Afin d’encourager les personnes interceptées à obtempérer avec
                les instructions des policiers, le législateur impose des{' '}
                <strong>sanctions plus sévères</strong> à l’individu qui refuse
                de souffler qu’à la personne ayant conduit avec les facultés
                affaiblies.
            </p>
            <p>
                Ainsi, si vous aviez l’intention de refuser de respecter l'ordre
                afin d’échapper aux conséquences de l’alcool au volant, cela
                n’est tout simplement pas une bonne idée. En effet, votre
                sanction sera encore plus grave que si vous aviez un taux
                d’alcoolémie supérieur à 80 mg d’alcool par 100 ml de sang.{' '}
            </p>
            <p>
                <strong>
                    Voici toutes les conséquences que vous pourriez subir si
                    vous refusez de souffler dans l’alcootest au Québec:
                </strong>
            </p>
            <h3>1) Avoir un casier judiciaire</h3>
            <p>
                Tout d’abord, lorsque vous êtes coupable d’avoir refusé de
                souffler dans un alcootest, vous aurez{' '}
                <strong>automatiquement un casier judiciaire</strong>. Bien que
                ce casier peut ne pas avoir de conséquences immédiates pour
                vous, il est important de comprendre ce que cela implique.{' '}
            </p>
            <p>
                Par exemple, si vous souhaitez vous déplacer à l'extérieur
                <strong> du pays</strong>, votre dossier criminel pourrait venir
                chambouler vos plans. Cela est également le cas dans plusieurs
                autres aspects de votre vie.{' '}
            </p>
            <h3>2) La saisie de votre véhicule</h3>
            <p>
                Avant même d’être officiellement coupable d’avoir refusé
                d’obtempérer avec l’ordre du policier qui vous a intercepté, ce
                dernier peut <strong>saisir votre véhicule</strong> pour une
                durée de 30 jours, et ce, même sans le verdict d’un juge.{' '}
            </p>
            <p>
                <strong>Le saviez-vous?</strong> Si vous n’en êtes pas à votre
                première infraction de la sorte dans les 10 dernières années,
                l’agent de la paix pourra alors automatiquement saisir votre
                véhicule pour une durée de 90 jours.{' '}
            </p>
            <h3>3) Le paiement d’une contravention</h3>
            <p>
                Une autre conséquence associée à cette infraction est la{' '}
                <strong>contravention</strong> que vous devrez payer.
                Normalement, lorsque vous êtes arrêté pour alcool au volant, la
                contravention minimale est de 1 000$.
            </p>
            <p>
                Cependant, si vous avez refusé de souffler dans l’alcootest, la
                contravention minimale sera alors de 2 000$, soit le double. De
                plus, ce montant minimal ne s’applique que dans le cas de votre{' '}
                <strong>première infraction</strong>. En effet, si vous êtes en
                récidive, la sanction minimale sera une peine d’emprisonnement.
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Infraction</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Sanction minimale</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Interdiction de conduire</strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Première </p>
                    </td>
                    <td>
                        <p>2 000$ en amende</p>
                    </td>
                    <td>
                        <p>1 an</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Deuxième</p>
                    </td>
                    <td>
                        <p>Peine d’emprisonnement: 30 jours</p>
                    </td>
                    <td>
                        <p>2 ans</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Troisième</p>
                    </td>
                    <td>
                        <p>Peine d’emprisonnement: 120 jours</p>
                    </td>
                    <td>
                        <p>3 ans</p>
                    </td>
                </tr>
            </table>
            <p>
                Ainsi, même si la contravention n’a lieu que pour la première
                infraction, les sanctions imposées en cas de récidive sont tout
                aussi sévères. De plus, puisqu’il s’agit du montant minimal, le
                juge peut décider de vous
                <strong> imposer une sanction plus sévère</strong>.{' '}
            </p>
            <h3>4) La suspension de votre permis</h3>
            <p>
                Finalement, si vous refusez de souffler dans l’alcootest, votre
                permis de conduire sera automatiquement suspendu pour{' '}
                <strong>une durée de 90 jours</strong>. Cependant, le juge
                pourrait prendre la décision de révoquer votre permis pour une
                période additionnelle.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img3.childImageSharp.fluid}
                    title="Quelle est la différence entre l’infraction d’alcool au volant et le refus de souffler dans l’alcootest?"
                    alt=""
                />
            </p>
            <p>
                Outre la suspension de votre permis, il est également possible,
                même si vous en êtes à votre première infraction, d’avoir un{' '}
                <strong>antidémarreur éthylométrique</strong>. Dans ce cas, il
                sera nécessaire de souffler dans le détecteur afin de pouvoir
                prendre le volant.{' '}
            </p>
            <p>
                Pour que votre véhicule puisse démarrer, vous devez avoir une
                absence complète d’alcool dans votre sang. Une telle sanction
                peut aussi être accompagnée d’une adhésion obligatoire au
                Programme d’évaluation et de réduction des risques de conduite
                avec les facultés affaiblies.
            </p>
            <p>
                <strong>
                    Minimiser les conséquences de votre refus de souffler en
                    faisant appel à un avocat spécialisé en alcool au volant!
                </strong>
            </p>
            <h2>
                Défendez-vous grâce à un avocat partenaire d’Avocats Alcool au
                Volant
            </h2>
            <p>
                Être reconnu coupable d’avoir commis une infraction en lien avec
                l’alcool au volant est souvent associé à de très lourdes
                conséquences. Cela est d’autant plus vrai si vous avez tout
                simplement refusé d’obtempérer avec le policier qui souhaite
                vous soumettre à un alcootest.{' '}
            </p>
            <p>
                Peu importe l’infraction relative à l’alcool au volant pour
                laquelle vous êtes reconnu coupable, les conséquences peuvent
                vous suivre pour une longue durée. Donc, lorsqu’un moyen de
                défense est disponible, mieux vaut s’assurer que vous êtes en
                mesure de le faire valoir.
            </p>
            <p>
                De plus, selon votre situation, il est possible que vous ne
                connaissiez pas les différents moyens de défense mis à votre
                disposition. De plus, avec le stress associé aux accusations
                portées contre vous, vous n’êtes peut-être pas la meilleure
                personne pour assurer votre défense.
            </p>
            <p>
                Heureusement, il vous est possible de
                <Link to="https://avocatsalcoolauvolant.ca/avocat-criminel-alcool-volant/">
                    {' '}
                    trouver un avocat
                </Link>{' '}
                qui spécialise sa pratique dans les défenses contre les
                accusations d’alcool au volant ou les infractions de refus de
                souffler dans l’alcootest.
            </p>
            <p>
                <strong>
                    N’attendez plus et remplissez dès maintenant le formulaire
                    en ligne d’Avocats Alcool au Volant pour trouver le
                    professionnel qu’il vous faut!
                </strong>
            </p>
        </SeoPage>
    )
}

export default ContentPage

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "refus-souffler-alcootest/refus-echantillon-haleine.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "refus-souffler-alcootest/consequences-refus-souffler.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "refus-souffler-alcootest/defense-refus-alcootest.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "refus-souffler-alcootest/alcool-volant-test.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
